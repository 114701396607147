<template>
  <div
    id="root-container"
    v-if="ready"
    class="container d-flex flex-column justify-content-between h-100"
  >
    <div>
      <div class="py-5 text-center">
        <img
          src="@/assets/colmeia.png"
          class="thumbnail my-2"
          width="200px"
          alt=""
        />
      </div>
      <b-alert show
        ><strong>Atenção:</strong> Confira as informações do pagador e
        beneficiário final antes de pagar o boleto</b-alert
      >
      <b-row :class="[$isMobile() ? 'panel' : false]">
        <b-col md="8" :class="[$isMobile() ? 'left-panel' : false]">
          <b-card
            header="Dados para pagamento do Boleto"
            header-text-variant="secondary"
          >
            <BankTicket />
          </b-card>
          <b-card
            class="mt-2"
            header="Dados do Pagador"
            header-text-variant="secondary"
          >
            <div class="d-flex flex-column align-items-start">
              <p class="text-muted">
                Cliente:
                {{ clientData.financialClient.financial_name }}
              </p>
              <p class="text-muted">
                CPF:
                {{ formatarCampo(clientData.financialClient.financial_cpf) }}
              </p>
            </div>
          </b-card>
          <b-card
            class="my-4"
            header="Dados do beneficiario final"
            header-text-variant="secondary"
          >
            <div class="d-flex flex-column align-items-start">
              <p class="text-muted">
                Cliente:
                {{ clientData.unity[0].commercial_name }}
              </p>
              <p class="text-muted">
                CNPJ:
                {{ formatarCampo(clientData.unity[0].cnpj) }}
              </p>
            </div>
          </b-card>
        </b-col>

        <b-col :class="[$isMobile() ? 'pt-3' : false]">
          <PanelDescription
            :items="clientData.items"
            :invoice="clientData.invoice"
            :stick="true"
          />
        </b-col>
      </b-row>
    </div>

    <footer
      class="my-5 pt-5 text-muted text-center text-small justify-self-end"
    >
      <p class="mb-1">&copy; {{ getYear }} - {{ unity.commercial_name }}</p>
      <!-- <ul class="list-inline">
        <li class="list-inline-item"><a href="#">Privacy</a></li>
        <li class="list-inline-item"><a href="#">Terms</a></li>
        <li class="list-inline-item"><a href="#">Support</a></li>
      </ul> -->
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ready: false,
    };
  },
  components: {
    Pix: () => import("./components/Pix.vue"),
    BankDeposit: () => import("./components/BankDeposit.vue"),
    BankTicket: () => import("./components/BankTicket.vue"),
    CreditCard: () => import("./components/CreditCard.vue"),
    PanelDescription: () => import("./components/PanelDescription.vue"),
  },
  computed: {
    getYear() {
      const temp = new Date();
      return new Date().getFullYear();
    },
    logo() {
      try {
        return this.unity.logo;
      } catch (error) {}
      // return `${process.env.VUE_APP_PREFIX_LOGO}/logo/${this.clientData.pageContent[0].unity_id}/logo.png`;
    },
    clientData() {
      return this.$store.state.pdf.clientData;
    },
    clientData() {
      return this.$store.state.pdf.clientData;
    },
    unity() {
      return this.clientData.unity[0];
    },
    pageContent() {
      return this.clientData.pageContent[0];
    },
    get_url() {},
  },
  methods: {
    formatarCampo(value) {
      let campoTexto = value;
      if (campoTexto.length <= 11) {
        campoTexto = this.mascaraCpf(campoTexto);
      } else {
        campoTexto = this.mascaraCnpj(campoTexto);
      }
      return campoTexto;
    },
    retirarFormatacao(campoTexto) {
      campoTexto = campoTexto.replace(/(\.|\/|\-)/g, "");
    },
    mascaraCpf(valor) {
      return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
    },
    mascaraCnpj(valor) {
      return valor.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        "$1.$2.$3/$4-$5"
      );
    },
  },
  async mounted() {
    const token = this.$route.params.token;

    if (token) {
      await this.$store.dispatch("pdf/init", token);
    }
    this.ready = true;
  },
};
</script>
<style>
body {
  height: unset !important;
  overflow: unset !important;
}
.td {
  position: relative;
}
.bank-card {
  padding: 1rem;
  background-color: rgb(231, 231, 231);
  border-radius: 10px;
  margin-top: 1rem;
}
.boleto-td {
  position: relative;
}
.boleto-table {
  position: relative;
}
.left-panel {
  display: flex;
  flex-direction: column;
}
.left-panel > :nth-child(1) {
  order: 3;
}
.left-panel > :nth-child(2) {
  order: 1;
}
.left-panel > :nth-child(3) {
  order: 2;
}
.panel {
  display: flex;
  flex-direction: column;
}
.panel > :nth-child(1) {
  order: 2;
}
.panel > :nth-child(2) {
  order: 1;
}
</style>
